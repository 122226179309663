/* global webchat */

var angular = require('angular')

angular.module('AemButtonChat')
  .controller('aemButtonChatCtrl', ctrl)

ctrl.$inject = ['appLoader', 'wgService', '$interval', '$timeout']

function ctrl (appLoader, wgService, $interval, $timeout) {
  var vm = this

  vm.$onInit = function () {
    var params = wgService.getParams()
    vm.buttonColor = angular.isUndefined(params.color) ? 'csas-click--aem' : 'csas-click--' + params.color
    vm.buttonSize = angular.isUndefined(params.size) ? '' : 'csas-click--' + params.size
    vm.buttonText = params.text || 'Zobrazit'
    vm.buttonMargin = angular.isUndefined(params.margin) ? '0' : params.margin
    vm.backgroundColor = params.background || ''
    vm.showTitle = angular.isUndefined(params.showTitle) ? true : params.showTitle
    vm.appleBusinessId = params.appleBusinessId || 'bec7a2ef-6b3c-4de3-8658-83aa89d47b8d'
    vm.isGwcAvailable = false
    vm.isAbcAvailable = false
    vm.showGwc = showGwc
    appLoader.hide()

    var promise = $interval(function () {
      if (isGwcOffering()) {
        vm.isGwcAvailable = true
        $interval.cancel(promise)
      }
      if (isAbcOffering()) {
        vm.isAbcAvailable = true
        $timeout(function () {
          try {
            window.appleBusinessChat.refresh()
          } catch (e) {
            vm.isAbcAvailable = false
          }
        })
        $interval.cancel(promise)
      }
    }, 1000, 60)
  }

  function showGwc () {
    if (isGwcOffering()) {
      webchat('postBack', 'start')
    }
  }

  function isGwcOffering () {
    return document.getElementById('chat-offering') || document.getElementById('webchat') ||
      document.getElementById('chat-offering-mobile')
  }

  function isAbcOffering () {
    return document.querySelector('.apple-business-chat-message-container')
  }
}

var inlet = require('aem-libs/src/initInlet.js').initInlet(
  'AemButtonChat',
  /* global WEBPACK_INLET_VERSION */ WEBPACK_INLET_VERSION
)
var angular = require('angular')

angular.module('AemButtonChat', [])

require('./inlet.css')
require('./inlet.controller.js')

require('aem-libs-angularjs/src/bootstrap').bootstrap({
  element: inlet.element,
  appName: 'AemButtonChat',
  mainCtrlName: 'aemButtonChatCtrl',
  basePath: inlet.basePath,
  config: /* global WEBPACK_INLET_CONFIG */ WEBPACK_INLET_CONFIG,
  supportedLanguages: {
    cs: require('./i18n/cs.js').cs,
    en: require('./i18n/en.js').en
  },
  html: require('./inlet.html')
})
